'use client';

import { cn } from '@/lib/utils/cn';
import { InputHTMLAttributes, forwardRef, useEffect, useState } from 'react';

type InputV2Props = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  error?: string;
  showLabel?: boolean;
  inputClassName?: string;
};

export const InputV2 = forwardRef<HTMLInputElement, InputV2Props>(
  function InputV2(
    {
      label,
      error,
      required,
      className,
      placeholder,
      showLabel,
      inputClassName,
      ...props
    },
    ref
  ) {
    return (
      <label
        className={cn(
          'group relative flex flex-col rounded border px-3 pt-4 pb-2 bg-white shadow focus-within:ring-1 focus-within:ring-black',
          className
        )}
      >
        <span
          className={cn(
            'absolute font-regular text-base text-black/50 transition-all left-3 group-hover:text-sm group-hover:-translate-y-3',
            // (hasFocus || show) && "text-sm -translate-y-3",
            'text-sm -translate-y-3'
          )}
        >
          {placeholder ?? label} {required && <em>(Required)</em>}
        </span>

        <input
          ref={ref}
          className={cn(
            'flex-1 border-0 p-0 outline-none placeholder:text-transparent focus:border-0 focus:outline-none focus:ring-0 pt-2',
            inputClassName
          )}
          autoFocus={false}
          placeholder={placeholder}
          {...props}
        />

        {!!error && <div className='text-jb-base text-ruby'>{error}</div>}
      </label>
    );
  }
);
