import { cn } from '@/lib/utils/cn';
import { TextareaHTMLAttributes, forwardRef, useState } from 'react';

type TextAreaV2 = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label: string;
  error?: string;
  showLabel?: boolean;
};

export const TextAreaV2 = forwardRef<HTMLTextAreaElement, TextAreaV2>(
  function InputV2(
    {
      label,
      error,
      required,
      className,
      placeholder,
      showLabel,
      onFocus,
      onBlur,
      onChange,
      ...props
    },
    ref
  ) {
    const [hasFocus, setHasFocus] = useState(false);
    const [virtualValue, setVirtualValue] = useState('');

    const show =
      !!virtualValue || !!props.value || !!props.defaultValue || showLabel;

    return (
      <label
        className={cn(
          'group relative flex flex-col w-full rounded border px-3 pt-4 pb-2 bg-white shadow focus-within:ring-1 focus-within:ring-black',
          className
        )}
      >
        <span
          className={cn(
            'absolute font-regular text-base text-black/50 transition-all left-3 group-hover:text-sm group-hover:-translate-y-3',
            (hasFocus || show) && 'text-sm -translate-y-3'
          )}
        >
          {placeholder ?? label} {required && <em>(Required)</em>}
        </span>

        <textarea
          ref={ref}
          {...props}
          className='min-h-20 h-full border-0 p-0 outline-none placeholder:text-transparent focus:border-0 focus:outline-none focus:ring-0 pt-2'
          onChange={(e) => {
            if (onChange) onChange(e);
            setVirtualValue(e.target.value);
          }}
          onFocus={(e) => {
            if (onFocus) onFocus(e);
            setHasFocus(true);
          }}
          onBlur={(e) => {
            if (onBlur) onBlur(e);
            setHasFocus(false);
          }}
          placeholder={placeholder}
        />

        {!!error && <div className='text-jb-base'>{error}</div>}
      </label>
    );
  }
);
