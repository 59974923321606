import CarretIcon from '@assets/icons/carat.svg';
import { cn } from '@/lib/utils/cn';
import { ButtonHTMLAttributes } from 'react';

type NextCarretButtonProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'children'
> & {
  isLoading?: boolean;
  text?: string;
};

const NextCarretButton = ({
  text = 'Next',
  disabled = false,
  className,
  isLoading = false,
  ...props
}: NextCarretButtonProps) => {
  return (
    <button
      disabled={disabled}
      className={cn(
        'font-base my-4 inline-flex items-center gap-1 self-center rounded-full bg-ruby px-4 py-2 font-moderat text-jb-base text-white disabled:opacity-50 min-h-[40px] min-w-[83px]',
        className
      )}
      {...props}
    >
      {isLoading ?
        <div className={'flex items-center justify-center gap-1 w-full'}>
          <div
            className={
              'w-4 h-4 border-2 border-white rounded-full animate-spin'
            }
          />
        </div>
      : <>
          {text}{' '}
          <CarretIcon
            className={'relative h-4 w-4 fill-current stroke-current'}
          />
        </>
      }
    </button>
  );
};

export default NextCarretButton;
