import { cn } from '@/lib/utils/cn';
import { InputHTMLAttributes, forwardRef } from 'react';

export type CheckboxV2Props = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type'
> & {
  label?: string;
};

export const CheckboxV2 = forwardRef<HTMLInputElement, CheckboxV2Props>(
  function CHeckboxV2({ label, className, ...props }, ref) {
    return (
      <label className='flex items-center space-x-2'>
        <input
          ref={ref}
          type='checkbox'
          className={cn(
            'rounded-full text-ruby focus:outline-none focus:ring-black focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-black',
            cn
          )}
          {...props}
        />

        <span>{label}</span>
      </label>
    );
  }
);
