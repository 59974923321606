'use client';

import { useExperience } from '@/modules/profile/profile.queries';
import { useModalStore } from '@/lib/client/store/modal.store';
import { UpsertExperienceDto } from '@/modules/profile/profile.dto';
import {
  Experience_Create,
  Experience_Update,
} from '@/chore/services/Profile.service';
import NextCarretButton from '@/components/buttons/NextCarretButton';
import { CheckboxV2 } from '@/components/inputs/CheckboxV2';
import { InputV2 } from '@/components/inputs/InputV2';
import { TextAreaV2 } from '@/components/inputs/TextAreaV2';
import { Loader } from '@/components/common/loaders/loaders';
import { useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { format, parseISO } from 'date-fns';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

const formId = 'form_create-experience';

type FormProps = {
  setIsSubmitting?: Dispatch<SetStateAction<boolean>>;
  buttonType?: 'add' | 'save';
  onSuccess?: () => Promise<void> | void;
  onStateChange?: (state: {
    isDirty: boolean;
    isSubmitting: boolean;
  }) => Promise<void> | void;
};

export function CreateExperienceForm({
  setIsSubmitting,
  onSuccess,
  onStateChange,
  ...props
}: FormProps) {
  const queryClient = useQueryClient();
  const { type, data, onClose } = useModalStore();
  const { data: experience, isLoading } = useExperience(
    type === 'create_experience' ? data?.experienceId ?? '' : ''
  );
  const form = useForm<UpsertExperienceDto>({
    defaultValues: {
      role: experience?.role ?? '',
      employer: experience?.employer ?? '',
      location: experience?.location ?? '',
      description: experience?.description ?? '',
      start_date:
        experience?.startDate ?
          format(parseISO(experience.startDate), 'yyyy-MM')
        : '',
      end_date:
        !experience?.isCurrent && experience?.endDate ?
          format(parseISO(experience.endDate), 'yyyy-MM')
        : '',
      is_current: !!experience?.isCurrent,
    },
    values: {
      role: experience?.role ?? '',
      employer: experience?.employer ?? '',
      location: experience?.location ?? '',
      description: experience?.description ?? '',
      start_date:
        experience?.startDate ?
          format(parseISO(experience.startDate), 'yyyy-MM')
        : '',
      end_date:
        !experience?.isCurrent && experience?.endDate ?
          format(parseISO(experience.endDate), 'yyyy-MM')
        : '',
      is_current: !!experience?.isCurrent,
    },
  });
  const isAddButton =
    props.buttonType === 'add' ||
    (type === 'create_experience' && data?.buttonType === 'add');
  const isCurrent = form.watch('is_current');
  const endDate = form.watch('end_date');

  useEffect(() => {
    if (isCurrent) {
      form.setValue('end_date', undefined);
    }
  }, [isCurrent, form]);

  useEffect(() => {
    if (!!endDate) {
      form.setValue('is_current', false);
    }
  }, [endDate, form]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty, errors },
  } = form;

  useEffect(() => {
    if (setIsSubmitting) {
      setIsSubmitting(isSubmitting);
    }
  }, [isSubmitting, setIsSubmitting]);

  useEffect(() => {
    if (onStateChange) {
      onStateChange({ isDirty, isSubmitting });
    }
  }, [isDirty, isSubmitting, onStateChange]);

  const onSubmit = handleSubmit(async (values) => {
    if (isSubmitting) return;

    if (!values.start_date.length) {
      form.setError('start_date', { message: 'Start date is required.' });
      return;
    }

    try {
      if (
        values.end_date &&
        new Date(values.end_date).getTime() <
          new Date(values.start_date).getTime()
      ) {
        form.setError('end_date', {
          message: 'End date must be greater than start date.',
        });
        form.setError('start_date', {
          message: 'Start date must b previous to end date',
        });

        return;
      }

      if (!values.end_date && values.is_current === undefined) {
        ['end_date', 'is_current'].forEach((field) =>
          form.setError(
            // @ts-ignore
            field,
            'Either end date or is current are required.'
          )
        );
        return;
      }

      const data = {
        ...values,
        start_date: new Date(values.start_date).toISOString(),
        end_date:
          values.end_date ? new Date(values.end_date).toISOString() : undefined,
      };

      if (experience) {
        await Experience_Update(experience.hash, data);
        toast.success(`Experience Updated!`);
      } else {
        await Experience_Create(data);
        toast.success(`${values.role} Added!`);
      }

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['experiences'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['profile-current'],
        }),
      ]);

      if (experience) {
        await queryClient.invalidateQueries({
          queryKey: [`experiences-${experience.hash}`],
        });
      }

      reset();
      onClose();

      if (onSuccess) {
        await onSuccess();
      }
    } catch (err: any) {
      if (isAxiosError(err)) {
        toast.error(err.response?.data?.message);
      }
    }
  });

  if (isLoading) {
    return (
      <div className='flex justify-center py-2'>
        <Loader className='w-8 h-8 text-ruby' />
      </div>
    );
  }

  return (
    <form
      id={formId}
      className='flex flex-col space-y-4 w-full'
      onSubmit={onSubmit}
    >
      <div className='space-y-4 px-2 py-1 max-h-[65svh] overflow-y-scroll scrollbar scrollbar-w-1.5 scrollbar-thumb-zinc-400 scrollbar-thumb-rounded-full'>
        <div className='space-y-2'>
          <InputV2
            label='Job Title'
            required
            defaultValue={form.getValues('role')}
            {...register('role', { required: true, minLength: 1 })}
          />
          <ErrorMessage
            message={
              errors.role?.type === 'required' ?
                'Job Title is required.'
              : errors.role?.message
            }
          />
        </div>

        <div className='space-y-2'>
          <InputV2
            label='Employer'
            required
            defaultValue={form.getValues('employer')}
            {...register('employer', {
              required: true,
              minLength: 1,
            })}
          />
          <ErrorMessage
            message={
              errors.employer?.type === 'required' ?
                'Employer is required.'
              : errors.employer?.message
            }
          />
        </div>

        <div className='space-y-2'>
          <InputV2
            label='Location'
            defaultValue={form.getValues('location')}
            {...register('location')}
          />
          <ErrorMessage message={errors.location?.message} />
        </div>

        <div className='space-y-2'>
          <TextAreaV2
            label='Job Details'
            defaultValue={form.getValues('description')}
            {...register('description')}
          />
          <ErrorMessage message={errors.description?.message} />
        </div>

        <div className='flex flex-col sm:flex-row items-center gap-4 overflow-hidden'>
          <div className='space-y-2 w-full'>
            <InputV2
              type='month'
              required
              label='Start Date'
              {...register('start_date', { required: true })}
            />
            <ErrorMessage
              message={
                errors.start_date?.type === 'required' ?
                  'Start date is required'
                : errors.start_date?.message
              }
            />
          </div>

          <div className='space-y-2 w-full'>
            <InputV2 label='End Date' type='month' {...register('end_date')} />
            <ErrorMessage message={errors.end_date?.message} />
          </div>
        </div>

        <div>
          <CheckboxV2
            label='I am currently working in this job'
            {...register('is_current')}
          />
        </div>
      </div>
      <div className='flex justify-center w-full'>
        {isAddButton ?
          <button
            type='submit'
            form={formId}
            disabled={isSubmitting || !isDirty}
            className='self-center inline-flex items-center justify-center text-ruby font-medium text-lg px-3 py-2 rounded-full transition-colors hover:bg-zinc-100 focus-visible:outline-none focus-visible:ring focus-visible:ring-black'
          >
            {isSubmitting ?
              <Loader className='w-5 h-5' />
            : '+ Add Another'}
          </button>
        : <NextCarretButton
            text='Save Experience'
            type='submit'
            form={formId}
            disabled={!isDirty}
            className='w-full justify-center items-center'
            isLoading={isSubmitting}
          />
        }
      </div>
    </form>
  );
}

type ErrorMessageProps = {
  message?: string;
};

function ErrorMessage({ message }: ErrorMessageProps) {
  if (!message) return null;
  return <p className='text-sm text-ruby'>{message}</p>;
}
